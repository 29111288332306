

import DateTools from '@/tools/date'
import UITools from '@/tools/ui'
import * as DataAPI from '@/apis/data'
import moment from 'moment-timezone'

// initial state
const state = {

    sort_photo: [
        { value: '-id',                 text: 'id 降序' },
        { value: 'id',                  text: 'id' },

        { value: '-log_time',           text: '記錄時間 降序' },
        { value: 'log_time',            text: '記錄時間' },

        { value: '-file_name',          text: '檔案名稱 降序' },
        { value: 'file_name',           text: '檔案名稱' },
    ],

    sort_timelapse: [
        { value: '-id',                 text: 'id 降序' },
        { value: 'id',                  text: 'id' },

        { value: '-start_time',         text: '開始時間 降序' },
        { value: 'start_time',          text: '開始時間' },

        { value: '-end_time',           text: '結束時間 降序' },
        { value: 'end_time',            text: '結束時間' },

        { value: '-created_at',         text: '建立時間 降序' },
        { value: 'created_at',          text: '建立時間' },
    ],

}



// getters
const getters = {

}

// actions
const actions = {

    get_Device_Data (context, settings)
    {
        return new Promise((resolve, reject) => {

            let _API = undefined
            // if ( settings.data_type === 'realtime' ) _API = 'api_Device_Realtime_Data'
            // else if ( settings.data_type === 'raw' ) _API = 'api_Device_Raw_Data'

            switch (settings.data_type)
            {
                case 'realtime':    _API = 'api_Device_Realtime_Data'; break;
                case 'raw':         _API = 'api_Device_Raw_Data'; break;
                case 'gateway':     _API = 'api_Gateway_Realtime_Data'; break;
                case 'accesspoint': _API = 'api_AccessPoint_Logs'; break;
                default: break;
            }

            if ( _API !== undefined )
            {
                DataAPI[ _API ](settings.params, settings.query)
                .then( res => {
                    let _deviceData = res.data[ settings.params.hashID ]

                    if ( _deviceData !== undefined )
                    {
                        let _timezone = ( settings.timezone ? settings.timezone : this.state.user.timezone );

                        // 資料從 UTC 轉換時區
                        _deviceData.time = DateTools.dateTimeZone_List( _deviceData.time, _timezone, 'YYYY-MM-DD HH:mm' )

                        // 將 WD 小於等於 -99 的數值轉為 null
                        let _deviceType = settings.params.hashID[0] + settings.params.hashID[1]
                        if ( _deviceType === 'WD' )
                        {
                            for (let key_param in _deviceData )
                            {
                                if ( key_param !== 'time' )
                                {
                                    for (let i=0; i < _deviceData[ key_param ].length; i++ )
                                    {
                                        if ( _deviceData[ key_param ][i] <= -99 )
                                        {
                                            _deviceData[ key_param ][i] = null
                                        }
                                    }
                                }
                            }
                        }

                        // 判斷有 [ 電壓 ] 資料就自動轉成 [ 電量百分比 ]，並儲存在 voltage_to_battery_percentage 內
                        if (_deviceData.voltage)
                        {
                            _deviceData.voltage_to_battery_percentage = UITools.getVoltageToPercentage(_deviceData.voltage);
                        }
                        // 判斷有 [ 訊號強度 ] 資料就自動轉成 [ 手機訊號格數 ]，並儲存在 rssi_to_signal_bar 內
                        if (_deviceData.rssi)
                        {
                            _deviceData.rssi_to_signal_bar = UITools.getRssiToSignalBar(_deviceData.rssi);
                        }
                        // 判斷有 [ 訊號品質指標 ] 資料就自動轉成 [ 訊號格數 ]，並儲存在 lqi_to_signal_bar 內
                        if (_deviceData.lqi && _deviceData.lqi.length > 0)
                        {
                            _deviceData.lqi_to_signal_bar = UITools.getLQIToSignalBar(_deviceData.lqi);
                        }
                        // 如果有紀錄時間與上傳時間，可以判斷是否為補傳資料
                        if (_deviceData.time && _deviceData.upload_time)
                        {
                          _deviceData.reupload = []
                          _deviceData.upload_timestamp = []

                          _deviceData.time.forEach( (_time, _idx) => {

                            let _timestamp = _deviceData.upload_time[_idx]
                            _deviceData.upload_timestamp.push( _timestamp )

                            let _timestamp_to_date = DateTools.timestampToDate( _timestamp )

                            _deviceData.upload_time[_idx] = _timestamp_to_date
                            _deviceData.reupload.push( moment(_timestamp_to_date).isAfter( _time ) )
                          });
                        }

                    }


                    resolve(_deviceData);
                })
                .catch( err => {
                    reject(err);
                })
            }
        })
    },



}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
