
import moment from 'moment-timezone'

/// moment.js 轉換的 format 筆記：
/// (1) HH:mm 表示時間格式為 24 小時
/// (2) hh:mm 表示時間格式為 12 小時
/// moment() = moment( new Date() ) => 非 UTC 的當下日期時間
/// moment().tz('Etc/UTC')          => UTC 的當下日期時間

export default {

    getDateSmaller: function (list_date = [])
    {
        if (list_date.length === undefined)  return undefined
        if (list_date.length === 0)          return undefined
        if (list_date.length === 1)          return list_date[0]

        let _resultDate = list_date[0];
        for (let i =1 ; i < list_date.length ; i++)
        {
            if ( moment( _resultDate ).isAfter( list_date[i] ) )
            {
               _resultDate = list_date[i]
            }
        }

        // console.log( 'smaller date: ' + _resultDate )
        return _resultDate
    },
    getDateBigger: function (list_date = [])
    {
        if (list_date.length === undefined)  return undefined
        if (list_date.length === 0)          return undefined
        if (list_date.length === 1)          return list_date[0]

        let _resultDate = list_date[0];
        for (let i =1 ; i < list_date.length ; i++)
        {
            if ( moment( _resultDate ).isBefore( list_date[i] ) )
            {
               _resultDate = list_date[i]
            }
        }

        // console.log( 'bigger date: ' + _resultDate )
        return _resultDate
    },

    getTimezoneNow: function ()
    {
        return moment.tz.guess()
    },

    getDateNow: function ( timezone = undefined, format = undefined )
    {
        if   (timezone === undefined) timezone = this.getTimezoneNow()  // 如果沒給時區就猜測當下時區
        else if (timezone === 'UTC') timezone  = 'Etc/UTC'

        if (format) return moment().tz( timezone ).format( format );
        else        return moment().tz( timezone );
    },

    /**
     * countDays: 計算距離開始日期到結束日期的天數
     * @param {string} start - 開始日期，格式 YYYY-MM-DD
     * @param {string} [end=undefined] - 結束日期，格式 YYYY-MM-DD，沒填就預設為當天時間
     */
    countDays: function ( start, end = undefined, timezone = undefined )
    {
        let _startDate, _endDate;
            _startDate      = moment(start);
        if   (!end) _endDate = this.getDateNow(timezone)  //moment(new Date());
        else _endDate        = moment(end);
        // console.log('countDays after moment:')
        // console.log(_startDate);
        // console.log(_endDate);
        return _endDate.diff(_startDate, 'days');
    },

    countPastTime: function ( start, end, unit = 'hours', showFloat = false, timezone = undefined )
    {
        let _startDate, _endDate;
            _startDate      = moment(start);
        if   (!end) _endDate = this.getDateNow(timezone)  //moment(new Date());
        else _endDate        = moment(end);
        // console.log(_startDate);
        // console.log(_endDate);

        if (showFloat) return _endDate.diff(_startDate, unit, true).toFixed(1);
        return _endDate.diff(_startDate, unit);
    },

    getDateShift: function ( start, shift, unit = 'days', format = 'YYYY-MM-DD HH:mm' )
    {
        let _startDate = moment(start);
        return _startDate.add(shift, unit).format(format);
    },

    dateTimeZone: function ( date_time, timezone = 'Asia/Taipei',  format = 'YYYY-MM-DD' )
    {
        if (!date_time) return date_time;

        let _UTC = moment.tz( date_time, 'Etc/UTC' );
        // console.log( '_UTC' );
        // console.log( _UTC.format('YYYY-MM-DD HH:mm:ss') );
        // console.log( timezone );
        // console.log( _UTC.tz( timezone ).format('YYYY-MM-DD HH:mm:ss') );

        return _UTC.tz( timezone ).format( format );
    },

    dateTimeZone_List: function ( date_time_list, timezone = 'Asia/Taipei', format = 'YYYY-MM-DD HH:mm' )
    {
        let _new_time_list = []

        date_time_list.forEach( date_time => {
            if (!date_time)
            {
                _new_time_list.push( date_time );
            }
            else
            {
                let _UTC = moment.tz( date_time, 'Etc/UTC' );
                _new_time_list.push( _UTC.tz( timezone ).format( format ) );
            }
        })
        return _new_time_list;
    },

    dataTimeZone_to_UTC: function ( date_time, timezone = 'Asia/Taipei', format = 'YYYY-MM-DD HH:mm:ss' )
    {
        let _Local = moment.tz( date_time, timezone );
        return _Local.tz( 'Etc/UTC' ).format(format);
    },

    dateFormat: function ( date_time, format = 'YYYY-MM-DD HH:mm' )
    {
        if (!date_time)
        {
            return date_time;
        }
        else
        {
            return moment( date_time ).format( format );
        }
    },

    timestampToDate: function ( date_time, format = 'YYYY-MM-DD HH:mm' )
    {
        if (!date_time)
        {
            return date_time;
        }
        else
        {
            return moment.unix( date_time ).format( format );
        }
    },

    dateFormat_List: function ( date_time_list, format = 'YYYY-MM-DD HH:mm' )
    {
        let _new_time_list = []

        date_time_list.forEach( date_time => {
            if (!date_time)
            {
                _new_time_list.push( date_time );
            }
            else
            {
                _new_time_list.push( moment( date_time ).format( format ) );
            }
        })
        return _new_time_list;
    },
}
